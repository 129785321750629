<template>
  <component :is="form === undefined ? 'div' : 'b-card'">
    <template>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row class="mt-3">
            <!-- New Password -->
            <b-col
              cols="12"
              sm="6"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="newPassword"
                rules="required"
              >
                <b-form-group
                  label="New Password"
                  label-for="new-password"
                >
                  <b-form-input
                    id="new-password"
                    v-model="form.password"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Re-tpye New Password -->
            <b-col
              cols="12"
              sm="6"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="ReNewPassword"
                rules="required"
              >
                <b-form-group
                  label="Re type New Password"
                  label-for="ReNewPassword"
                >
                  <b-form-input
                    id="ReNewPassword"
                    v-model="form.password_confirmation"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Form Actions -->
            <b-col cols="4">
              <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="mr-2"
                  type="submit"
                >
                  change
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-danger"
                >
                  reset
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </component>
</template>

<script>
import {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BMedia,
    BFormFile,
    BImg,
    BCardText,
    BButton,
    BMediaBody,
    BMediaAside,
    BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import UserRepository from '@/abstraction/repository/userRepository'
import userStoreModule from './userStoreModule'
import useUsersList from './useUsersList'

const userRepository = new UserRepository()
export default {
    components: {
        BTab,
        BFormInput,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    data: () => ({
        required,
        profileFile: null,
    }),
    setup() {
        const blankform = {
            password: '',
            password_confirmation: '',
        }

        const form = ref(JSON.parse(JSON.stringify(blankform)))

        const USER_APP_STORE_MODULE_NAME = 'app-user'

        const { refetchData } = useUsersList()
        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })
        const resetform = () => {
            form.value = JSON.parse(JSON.stringify(blankform))
        }
        const userId = ref(router.currentRoute.params.id)

        const onSubmit = async () => {
            const response = await userRepository.changePassword(userId.value, form.value)
            if (response.status === '200') {
                router.push({ name: 'apps-users-list' })
            }
        }
        const { refFormObserver, getValidationState, resetForm } = formValidation(resetform)
        return {
            form,
            refetchData,
            onSubmit,

            refFormObserver,
            getValidationState,
            resetForm,
        }
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
